import { useEffect } from 'react'
import useAppContext from 'app/store/useAppContext'

import monsterImg from 'shared/assets/images/thank/monster.png'
import backImg from 'shared/assets/images/thank/back.png'
import mobilebackImg from 'shared/assets/images/thank/mobileback.png'

import './ThankPage.scss'

export const ThankPage = () => {
  const {
    store: {
      user: { searchParams, info },
    },
  } = useAppContext()

  useEffect(() => {
    if (searchParams?.metric) {
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(searchParams?.metric.toString())
      ReactPixel.default.track('Lead')
    }
  }, [])

  return (
    <div className="thank_page">
      <div className="container">
        <div className="mobback-wrapper">
          <img src={mobilebackImg} alt="mobback" className="mobback" />
          <img src={monsterImg} alt="mobmonster" className="mobmonster" />
        </div>
        <div className="center">
          <div className="info-wrapper">
            <h1>Спасибо!</h1>
            <h3>
              Ваш запрос принят и обработан! Наш специалист свяжется с вами в
              ближайшее время
            </h3>
            <h4>Обязательно возьмите телефон!</h4>
          </div>
        </div>
      </div>
      <div className="pcback-wrapper">
        <img src={monsterImg} alt="pcmonster" className="pcmonster" />
        <img src={backImg} alt="back" className="back" />
      </div>
    </div>
  )
}
