import React, { Dispatch, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import type { IAction } from 'app/store/model/action-types'
import { setUserAccepted, setUserInfo } from 'app/store/actions'
import { IUser } from 'entities/user/model/user'
import { ROUTES } from 'shared/config/routes'
import { useForm } from 'shared/lib/react-hook-form'
import { yupResolver } from 'shared/lib/yup-resolver'
import InputMask from 'shared/lib/input-mask'
import { Button } from 'shared/ui'

import type { IFormInputs } from '../_model/IFormInputs'
import schema from '../_schema/schema'
import { PHONE_MASK, PHONE_TITLE } from '../_model/constants'
import { sendUserData } from '../_use-cases/send-user-data'
import { phoneMaskChange } from '../_use-cases/phone-mask-change'

import cls from './Form.module.scss'

interface AffirmationFormProps {
  user: IUser
  dispatch: Dispatch<IAction>
  title?: string
  className?: string
  isAffirmationModal?: boolean
  isMobile500?: boolean
}

export function AffirmationForm(props: AffirmationFormProps) {
  const { user, dispatch, title, isAffirmationModal, isMobile500, className } =
    props

  const navigate = useNavigate()

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormInputs>({
    defaultValues: {
      phoneNumber: '',
      country: user.api.country,
      ip: user.api.ip,
      external_id: user.searchParams.external_id,
      metric: user.searchParams.metric,
      gaid: user.searchParams.gaid,
      sub1: user.searchParams.sub1,
      sub2: user.searchParams.sub2,
      sub3: user.searchParams.sub3,
      sub4: user.searchParams.sub4,
      sub5: user.searchParams.sub5,
      sub6: user.searchParams.sub6,
      sub7: user.searchParams.sub7,
      apps_id: user.searchParams.apps_id,
      devKey: user.searchParams.devKey,
      bundle: user.searchParams.bundle,
      status: user.searchParams.status,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  })

  const errFirstName = errors?.firstName?.message
  const errLastName = errors?.lastName?.message
  const errEmail = errors?.email?.message
  const errPhoneNumber = errors?.phoneNumber?.message

  const isErrors = errFirstName || errLastName || errEmail || errPhoneNumber

  const onSubmit = handleSubmit(async (data: any) => {
    setValue('external_id', user.searchParams.external_id)
    setValue('metric', user.searchParams.metric)
    setValue('gaid', user.searchParams.gaid)
    setValue('sub1', user.searchParams.sub1)
    setValue('sub2', user.searchParams.sub2)
    setValue('sub3', user.searchParams.sub3)
    setValue('sub4', user.searchParams.sub4)
    setValue('sub5', user.searchParams.sub5)
    setValue('sub6', user.searchParams.sub6)
    setValue('sub7', user.searchParams.sub7)
    setValue('apps_id', user.searchParams.apps_id)
    setValue('devKey', user.searchParams.devKey)
    setValue('bundle', user.searchParams.bundle)
    setValue('status', user.searchParams.status)

    await sendUserData({
      ...data,
      country: user.api.country,
      ip: user.api.ip,
    }).then(() => {
      dispatch(
        setUserInfo({
          firstName: data?.firstName,
          lastName: data?.lastName,
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          callHours: data?.callHours,
        }),
      )

      const htmlElement = document.querySelector('html')
      if (htmlElement) htmlElement.classList.remove('html_home')

      navigate(ROUTES.THANK, { replace: true })
    })
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(
        setUserAccepted({
          isPolitics: true,
          isAge: true,
          whatsapp: true,
          telegram: false,
          viber: false,
        }),
      )

      reset()
    }
  }, [isSubmitSuccessful, reset])

  const modalId = isAffirmationModal ? '-modal' : ''

  return (
    <div className={cls.form_wrapper}>
      <form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(
          cls.form,
          isAffirmationModal && cls.form_modal,
          isMobile500 && cls.form_mobile,
          className,
        )}
      >
        <div
          className={classNames(
            cls.form__input_block,
            errFirstName && cls.form__input_block_error,
          )}
        >
          <input
            {...register('firstName')}
            id={'firstName' + modalId}
            name={'firstName'}
            placeholder="Имя"
            type="text"
          />
          {errors?.firstName && (
            <p className={cls.errors}>{errors.firstName.message}</p>
          )}
        </div>
        <div
          className={classNames(
            cls.form__input_block,
            errLastName && cls.form__input_block_error,
          )}
        >
          <input
            {...register('lastName')}
            id={'lastName' + modalId}
            name={'lastName'}
            placeholder="Фамилия"
            type="text"
          />
          {errors?.lastName && (
            <p className={cls.errors}>{errors.lastName.message}</p>
          )}
        </div>
        <div
          className={classNames(
            cls.form__input_block,
            errEmail && cls.form__input_block_error,
          )}
        >
          <input
            {...register('email')}
            id={'email' + modalId}
            name={'email'}
            placeholder="E-mail"
            type="text"
          />
          {errors?.email && (
            <p className={cls.errors}>{errors.email.message}</p>
          )}
        </div>
        <div
          className={classNames(
            cls.form__input_block,
            errPhoneNumber && cls.form__input_block_error,
            cls['form__input_block--tel'],
          )}
        >
          <InputMask
            {...register('phoneNumber', { required: true })}
            className={cls.inputPhone}
            id={'phoneNumber' + modalId}
            name={'phoneNumber'}
            type="tel"
            placeholder={`${PHONE_TITLE} Мобильный телефон`}
            mask={PHONE_MASK}
            maskPlaceholder=""
            alwaysShowMask={false}
            beforeMaskedStateChange={phoneMaskChange}
          />
          {errors?.phoneNumber && (
            <p className={cls.errors}>{errors.phoneNumber.message}</p>
          )}
        </div>

        <>
          <input
            {...register('country')}
            id={'country' + modalId}
            name={'country'}
            type="hidden"
          />
          <input
            {...register('ip')}
            id={'ip' + modalId}
            name={'ip'}
            type="hidden"
          />
          <input
            {...register('external_id')}
            id={'external_id' + modalId}
            name={'external_id'}
            type="hidden"
          />
          <input
            {...register('metric')}
            id={'metric' + modalId}
            name={'metric'}
            type="hidden"
          />
          <input
            {...register('gaid')}
            id={'gaid' + modalId}
            name={'gaid'}
            type="hidden"
          />
          <input
            {...register('sub1')}
            id={'sub1' + modalId}
            name={'sub1'}
            type="hidden"
          />
          <input
            {...register('sub2')}
            id={'sub2' + modalId}
            name={'sub2'}
            type="hidden"
          />
          <input
            {...register('sub3')}
            id={'sub3' + modalId}
            name={'sub3'}
            type="hidden"
          />
          <input
            {...register('sub4')}
            id={'sub4' + modalId}
            name={'sub4'}
            type="hidden"
          />
          <input
            {...register('sub5')}
            id={'sub5' + modalId}
            name={'sub5'}
            type="hidden"
          />
          <input
            {...register('sub6')}
            id={'sub6' + modalId}
            name={'sub6'}
            type="hidden"
          />
          <input
            {...register('sub7')}
            id={'sub7' + modalId}
            name={'sub7'}
            type="hidden"
          />
          <input
            {...register('apps_id')}
            id={'apps_id' + modalId}
            name={'apps_id'}
            type="hidden"
          />
          <input
            {...register('devKey')}
            id={'devKey' + modalId}
            name={'devKey'}
            type="hidden"
          />
          <input
            {...register('bundle')}
            id={'bundle' + modalId}
            name={'bundle'}
            type="hidden"
          />
          <input
            {...register('status')}
            id={'status' + modalId}
            name={'status'}
            type="hidden"
          />
        </>

        <Button
          type="submit"
          variant={!!isErrors ? 'disabled' : 'default'}
          disabled={!!isErrors}
        >
          Приступить
        </Button>
      </form>
    </div>
  )
}
